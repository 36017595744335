<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="签证明细" :callback="back" type="SMP" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img :src="detail.imagePath" class="img"
      /></van-swipe-item>
    </van-swipe>
    <van-row class="simple">
      <van-col span="24">
        <van-row class="name">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">签证名称</van-tag></van-col
          >
          <van-col span="18">
            {{ detail.visaName }}
          </van-col>
        </van-row>
        <van-row class="issuer">
          <van-col span="6" class="tle text-short"
            ><van-tag mark :color="COLOR_M">签证国家</van-tag></van-col
          >
          <van-col span="6">
            {{ detail.countryName }}
          </van-col>
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">面试类型</van-tag></van-col
          >
          <van-col span="6">
            <span v-if="detail.interviewType === 'RDM'">随机抽取</span
            ><span v-if="detail.interviewType === 'NOT'">无需面试</span
            ><span v-if="detail.interviewType === 'MST'">必须面试</span>
          </van-col>
        </van-row>
        <van-row class="issuer">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">有效期限</van-tag></van-col
          >
          <van-col span="18">
            {{ detail.validityPeriod }}
          </van-col>
        </van-row>
        <van-row class="issuer">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">停留期限</van-tag></van-col
          >
          <van-col span="18">{{ detail.stayPeriod }} </van-col>
        </van-row>
        <van-row class="issuer">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">入境次数</van-tag></van-col
          >
          <van-col span="18">
            {{ detail.entryCount }}
          </van-col>
        </van-row>
        <van-row class="issuer">
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">办理原价</van-tag></van-col
          >
          <van-col span="6">{{ detail.priceOld }}元 </van-col>
          <van-col span="6" class="tle"
            ><van-tag mark :color="COLOR_M">优惠价格</van-tag></van-col
          >
          <van-col span="6" class="color-s1">{{ detail.priceNew }}元 </van-col>
        </van-row>
        <div class="hr"></div>
        <van-row class="service">
          <van-col span="3"
            ><svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-service"></use></svg
          ></van-col>
          <van-col span="15" class="text"
            ><span class="color-s2">专业团队，快速出证</span></van-col
          >
          <van-col span="6" class="button">
            <van-button
              class="btn"
              icon="gold-coin-o"
              :color="COLOR_M"
              size="mini"
              @click="createOrder()"
              >咨询申请</van-button
            >
          </van-col>
        </van-row>
        <div class="hr"></div>
        <van-row class="service">
          <van-col span="3"
            ><svg class="icon02" aria-hidden="true">
              <use xlink:href="#icon-talk"></use></svg
          ></van-col>
          <van-col span="15" class="text"
            ><span class="color-s2">线上客服免费咨询</span></van-col
          >
          <van-col span="6" class="button">
            <van-button
              class="btn"
              icon="chat-o"
              :color="COLOR_S1"
              size="mini"
              @click="onlineChat()"
              >在线咨询</van-button
            >
          </van-col>
        </van-row>
        <!-- <van-row class="tips color-s2">
          <van-col span="4">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-notice"></use></svg
          ></van-col>
          <van-col span="20" class="text"
            >推荐人员入职成功，可获取高额奖励</van-col
          >
        </van-row> -->
      </van-col>
    </van-row>
    <van-row @click="home()">
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/wp-focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="detail">
      <van-tabs :active="materialActive" :color="COLOR_M">
        <div v-for="material in detail.materials" :key="material">
          <van-tab title="在职人员" v-if="material.type === 'STF'"
            ><div v-html="material.content"></div
          ></van-tab>
          <van-tab title="自由职业者" v-if="material.type === 'FRL'"
            ><div v-html="material.content"></div
          ></van-tab>
          <van-tab title="退休人员" v-if="material.type === 'RTE'"
            ><div v-html="material.content"></div
          ></van-tab>
          <van-tab title="在校学生" v-if="material.type === 'STD'"
            ><div v-html="material.content"></div
          ></van-tab>
          <van-tab title="学龄前儿童" v-if="material.type === 'CHD'"
            ><div v-html="material.content"></div
          ></van-tab>
        </div>
      </van-tabs>
      <div v-html="detail.description"></div>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="false"
      clue-model="VISA_CONSULT_ONLINE"
    />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="RCT" module="RCT_DETAIL" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
